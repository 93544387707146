.SmallDisclosure {
  position: relative;
  padding: 4px 0 4px;
}
.SmallDisclosureContainer {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.SmallDisclosureButtonOpen {
  color: #8f9398;
  font-size: 10px;
  text-align: left;
  border: none;
  background: transparent;
  svg {
    width: 14px;
    margin-right: 7px;
  }
}
