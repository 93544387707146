$mainColor: #35ac39;
$textColor: #666666;
$backgroundColor: #f5f8fa;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: $textColor;
  background: $backgroundColor;
  font-size: 14px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}

img,
svg {
  vertical-align: middle;
}

.mt-50 {
  margin-top: 50px !important;

  @media (max-width: 420px) {
    margin-top: 14px !important;
  }
}

.img-center {
  text-align: center;
  img {
    max-width: 100%;
  }
}

.img-center-left,
.img-center-right {
  margin-bottom: 20px;
  img {
    max-width: 300px;
  }
}

.img-center-left {
  float: left;
  margin-right: 20px;
}

.img-center-right {
  float: right;
  margin-left: 20px;
}

.img-responsive {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.text-center {
  text-align: center;
}

button {
  cursor: pointer;
  outline: none;
}

a {
  color: #1564bf;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1200px;
  width: 100%;
}

.btn-0 {
  display: inline-block;
  text-decoration: none;
  background: #ff4a64;
  border: 1px solid #ff4a64;
  border-radius: 500px;
  color: #ffffff;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 30px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
  line-height: initial;

  &-min {
    padding: 4px 15px;
  }
  &:hover {
    color: #ffffff !important;
    background: darken($mainColor, 10%);
    border-color: darken($mainColor, 10%);
  }
}
.btn-banner {
  text-transform: uppercase;
  font-size: 26px;
  margin-top: 25px;
}
.btn-1 {
  color: $mainColor;
  display: inline-block;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  &-min {
    font-size: 12px;
  }
  &:hover {
    text-decoration: underline;
  }
}

.btn-wide {
  width: 100%;
  text-align: center;
}

.header {
  padding: 30px 0;
  &-fix {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
  &-bg {
    background: #000000;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  &__logo {
    font-size: 28px;
    font-weight: 700;
    a {
      color: #ffffff;
      display: inline-block;
      text-decoration: none;
      img {
        max-width: 280px;
      }
    }
  }
  &__nav {
    & > .btn-open-nav {
      display: none;
    }
    & > ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;
      list-style: none;
      & > li {
        margin-right: 30px;
        & > a {
          color: #ffffff;
          text-decoration: none;
          &:hover,
          &.active {
            color: $mainColor;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.main-block {
  position: relative;
  background: url(./images/cover.jpg) center bottom no-repeat;
  background-size: cover;
  padding-top: 140px;
  padding-bottom: 80px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
  }
}

.hero-text {
  color: #ffffff;
  position: relative;
  z-index: 1;
  &__tx {
    width: 50%;
    h1 {
      font-size: 48px;
      line-height: 1.1;
      margin-bottom: 20px;
    }
    p {
      line-height: 1.9;
    }
  }
  &__btn-bl {
    margin-top: 20px;
  }
}
.section-bottom {
  padding: 0px 0 50px;
}
.section {
  padding: 30px 0 50px;
}
.bg-block {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(18, 18, 21, 0.6);
    z-index: 1;
  }
  &__text {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    color: #fff;
    font-size: 21px;
    font-weight: 600;
    & h1 {
      text-transform: uppercase;
      max-width: 57%;
      font-weight: 700;
      font-size: 36px;
      line-height: 50px;
      letter-spacing: 1px;
    }
  }
  padding: 200px;
}

.title-block {
  margin: 0 auto 10px;
  width: 100%;
  h3 {
    font-size: 46px;
    font-weight: 500;
  }
  p {
    font-size: 16px;
    margin-top: 15px;
    line-height: 1.7;
  }
}

.SmallDisclosure {
  & > .SmallDisclosureContainer {
    padding: 0;
    padding-bottom: 4px;
  }
}

.table-sight {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background: inherit;
  color: #333;
  text-transform: uppercase;
  margin-bottom: 0px;

  & > svg {
    margin-right: 8px;
  }
}

.block-2 {
  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 -15px;
  }
  &__col {
    width: 33.33%;
    padding: 0 15px;
  }
  &__item {
    padding: 60px;
    text-align: center;
    margin-bottom: 30px;
    border-radius: 3px;
    background: #ffffff;
    transition: all 0.3s ease 0s;
    z-index: 2;
    &__ico {
      svg {
        height: 80px;
      }
    }
    &__name {
      font-size: 18px;
      font-weight: 700;
      margin-top: 25px;
    }
    &__tx {
      margin-top: 15px;
    }
  }
}

.block-3 {
  background: #ffffff;
}

.info-dating-sites {
  &__tx-bl {
    p {
      line-height: 1.8;
      margin-bottom: 20px;
    }
    .img-left {
      float: left;
      margin-right: 30px;
    }
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
  &__btn-bl {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
    & > a {
      margin-right: 30px;
    }
  }
  &__info {
    margin-top: 40px;
    &__row {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 0 -10px;
    }
    &__col {
      padding: 0 10px;
      width: 33.33%;
    }
    &__item {
      text-align: center;
      padding: 40px;
      background: #ffffff;
      &__ico {
        svg {
          height: 60px;
        }
      }
      &__name {
        margin-top: 15px;
        font-size: 16px;
        font-weight: 500;
      }
      &__tx {
        font-size: 22px;
        font-weight: 700;
      }
    }
  }
}

.block-4 {
  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 -15px;
  }
  &__col {
    width: 33.33%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
  &__item {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    padding: 20px 20px 20px 0;
    &:after {
      content: '';
      display: block;
      background: #45a9e0 url(./images/communication.svg) center no-repeat;
      background-size: 26px;
      width: 50px;
      height: 50px;
      z-index: 11;
      position: absolute;
      border-radius: 50%;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      background: #ffffff;
      left: 25px;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      border-radius: 3px;
      border: 1px solid #f1f1f1;
    }
    &__photo {
      flex: 0 0 60px;
      img {
        width: 100%;
        border-radius: 50%;
      }
    }
    &__content {
      padding-left: 65px;
      flex: auto;
      &__name {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 8px;
        a {
          color: $mainColor;
          text-decoration: none;
        }
      }
    }
  }
}

.top {
  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin: 0 -15px;
  }
  &__col {
    width: 33.33%;
    padding: 0 15px;
  }
  &__item {
    background: #ffffff;
    padding: 20px 20px;
    border-radius: 5px;
    position: relative;
    z-index: 2;
    border: 1px solid #eee;
    transition: 0.3s;
    margin-bottom: 30px;
    overflow: hidden;
    &:hover {
      box-shadow: 0 60px 60px rgba(0, 0, 0, 0.1);
      z-index: 2;
      transform: translate(0, -10px);
    }
    .isFavorite {
      position: absolute;
      top: 0;
      left: 0;
      width: 60px;
      height: 60px;
      background: #feb000;
      border-radius: 0;
      text-align: center;
      line-height: 60px;
      border-bottom-right-radius: 10px;
      svg {
        width: 40px !important;
        height: 40px !important;
        path {
          fill: #ffffff;
        }
      }
    }
    &__logo {
      text-align: center;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      a {
        width: 100%;
        display: block;
      }
      img {
        max-width: 60%;
        max-height: 35px;
      }
    }
    &__stars {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 -3px 20px;
    }
    &__star {
      padding: 0 3px;
      svg {
        width: 15px;
        path {
          fill: #ffaf00;
        }
      }
    }
    &__text {
      text-align: center;
      margin-bottom: 20px;
      font-size: 12px;
      ul {
        list-style: none;
        li {
          margin-bottom: 5px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    &__btn-1 {
      margin-bottom: 10px;
      text-align: center;
    }
    &__btn-2 {
      text-align: center;
    }
  }
}

.footer {
  &__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    & > li {
      margin: 0 20px;
    }
  }
  &__top {
    padding: 40px 0;
    background: #ffffff;
  }
  &__bottom {
    padding: 30px 0;
    text-align: center;
  }
}

.table-top {
  box-shadow: 0 60px 60px 60px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  margin-bottom: 30px;
  text-align: center;

  &__tr {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
    position: relative;
    padding-top: 38px;
    padding-bottom: 24px;

    .popular_choice {
      position: absolute;
      top: 0;
      left: 0;
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      background: #035d4c;
      padding: 5px 5px 5px 9px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: -15px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 15px 0 0;
        border-color: transparent;
        border-top-color: #035d4c;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        right: -15px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 0 0 15px;
        border-color: transparent;
        border-left-color: #035d4c;
      }

      &_hidden {
        display: none;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .list-btns-wrap {
    display: none;
  }

  &__td {
    text-decoration: none;
    padding: 10px;

    &:nth-child(2) {
      width: 30%;
      text-align: center;
    }
    &:nth-child(3) {
      width: 45%;
    }
    &:nth-child(4) {
      width: 34%;
      text-align: center;
    }
  }
  &__logo {
    text-align: center;
    margin-bottom: 20px;

    img {
      max-width: 80%;
    }

    .table-top__score {
      display: none;
    }
  }
  &__users-votes {
    margin-top: 10px;
  }
  &__stars {
    display: flex;
    justify-content: center;
    align-items: center;
    &__item {
      margin: 0 2px;
      svg {
        width: 25px;
        height: auto;
        path {
          color: #e19441;
        }
      }
    }
  }
  &__users-votes {
    text-align: center;
    color: #b3b3b3;
  }
  &__plus {
    margin: 0 0 0 20px !important;
    text-align: left;
    li {
      position: relative;
      font-size: 15px;
      margin-bottom: 5px !important;

      @media (max-width: 420px) {
        line-height: initial;
      }

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
  &__score {
    font-size: 38px;
    line-height: 1;
    text-shadow: 1px 2px 0 #d0d0d0;
    color: #2e7675;
    margin-bottom: 10px;
    margin-top: 4px;
    font-weight: 600;
    letter-spacing: 0.04em;
    a {
      text-decoration: none;
      color: #2e7675;
    }
  }
  &__btn-1 {
    margin-bottom: 5px;
  }
}

.top-min {
  &__item {
    border: 1px solid rgb(219, 221, 222);
    box-shadow: 0 60px 60px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
    &__left {
      padding: 10px;
      width: 60%;
      background: #f5f8fa;
      text-decoration: none;
    }
    &__right {
      padding: 10px;
      width: 40%;
    }
    &__logo {
      img {
        max-width: 100%;
      }
      text-align: center;
      margin-bottom: 5px;
    }
    &__btns {
      text-align: center;
    }
    &__info {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: -5px;
      &__score {
        &__tx {
          text-align: center;
          font-size: 12px;
          margin-bottom: -1px;
        }
        &__stars {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &__star {
          margin: 0 1px;
          line-height: 1;
          svg {
            width: 10px;
            height: auto;
          }
        }
        &__number {
          font-size: 36px;
          margin-left: 8px;
          color: #000000;
          line-height: 1;
          a {
            color: #000000;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.advertising-disclosure {
  position: relative;
  margin: 40px 0;
  &__btn {
    text-decoration: none;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    margin: 0 auto;
    &__ico {
      position: relative;
      top: -2px;
      vertical-align: middle;
      svg {
        width: 20px;
      }
    }
    &__tx {
      margin-left: 7px;
      vertical-align: middle;
    }
  }
  &__popup {
    display: block;
    position: absolute;
    width: 500px;
    box-shadow: 0 15px 60px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background: #ffffff;
    color: #000000;
    text-align: left;
    left: 50%;
    top: -250px;
    margin-top: 16px;
    transform: translate(-50%, 0);
    font-size: 12px;
    z-index: 11;
    @media (max-width: 768px) {
      width: 300px;
    }
    &__close {
      position: absolute;
      right: -12px;
      top: -12px;
      width: 30px;
      height: 30px;
      border: none;
      border-radius: 50%;
      background: #35ac3a;
      transition: 0.3s;
      svg {
        width: 20px;
        height: 20px;
      }
      &:hover {
        background: #208b26;
      }
    }
  }
}

.main {
  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .content {
      flex: auto;
      padding-right: 30px;
      &__text {
        line-height: 1.8;
        h1 {
          margin-bottom: 30px;
          color: #333333;
          line-height: 1.5;

          @media (max-width: 420px) {
            margin-bottom: 8px;
          }
        }
        h2 {
          margin-bottom: 20px;
        }
        h3 {
          margin-bottom: 20px;
          font-weight: 500;
        }
        p {
          margin-bottom: 20px;
        }
        ol,
        ul {
          margin-left: 40px;
          margin-bottom: 20px;
          li {
            margin-bottom: 8px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &_review {
          padding-top: 24px;
        }
      }
    }
    .right-col {
      flex: 0 0 300px;
      max-width: 300px;
      position: sticky;
      top: 30px;
      bottom: 30px;

      &__title {
        font-size: 22px;
        font-weight: 700;
        text-align: center;
        color: $mainColor;
        margin-bottom: 20px;
        text-transform: uppercase;
      }
    }
  }
}

.info-reviews {
  box-shadow: 0 60px 60px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  padding: 20px;
  margin-bottom: 30px;
  text-align: center;
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__col {
    padding: 0 20px;
    img {
      max-width: 170px;
      max-height: 35px;
    }
  }
  .stars-review {
    display: flex;
    justify-content: center;
    align-items: center;
    &__item {
      margin: 0 2px;
      svg {
        width: 27px;
      }
    }
  }
  .stars-review__score {
    font-size: 11px;
  }
  .score-review {
    font-size: 36px;
    font-weight: 300;
    color: $mainColor;
    margin-bottom: 0;
    margin-top: 0;
    line-height: 1;
    a {
      color: #35ac39;
      text-decoration: none;
    }
  }
  .our-score-review {
    font-weight: 300;
  }
}

@media (min-width: 1400px) {
  .react-parallax-bgimage {
    margin-top: -85px;
  }
}
@media (min-width: 1600px) {
  .react-parallax-bgimage {
    margin-top: -115px;
  }
}
@media (max-width: 1200px) {
  .container {
    max-width: 996px;
  }
  .top__row {
    margin: 0 -10px;
  }
  .top__col {
    padding: 0 10px;
    width: 31%;
  }
  .top__item {
    margin-bottom: 20px;
  }
  .table-top__stars__item svg {
    width: 20px;
  }
}

@media (min-width: 768px) and (max-width: 996px) {
  .info-reviews__col img {
    max-width: 120px;
    max-height: 30px;
  }
  .info-reviews__col .btn-0 {
    padding: 7px 10px 6px;
  }
  .info-reviews .score-review {
    font-size: 28px;
  }
  .info-reviews .our-score-review {
    font-size: 12px;
  }
  .info-reviews__col {
    padding: 0 10px;
  }
  .info-reviews__row {
    margin: 0 -10px;
  }
  .info-reviews {
    padding: 15px;
  }
  .info-reviews .stars-review__item svg {
    width: 15px;
  }
}

@media (max-width: 996px) {
  .container {
    max-width: 768px;
  }
  .hero-text__tx {
    width: 75%;
  }
  .block-2__item {
    padding: 30px;
  }
  .block-2__item__ico svg {
    height: 50px;
  }
  .block-2__item__name {
    margin-top: 16px;
  }
  .block-2__item__tx {
    margin-top: 10px;
  }
  .top__row {
    justify-content: center;
    margin: 0 -15px;
  }
  .top__col {
    padding: 0 15px;
    width: 33.33%;
  }
  .top__item {
    margin-bottom: 30px;
  }
  .block-4__col {
    width: 50%;
  }
  .main__row .right-col {
    flex: 0 0 270px;
    max-width: 270px;
  }
  .top-min__item__left {
    width: 55%;
  }
  .top-min__item__right {
    width: 45%;
  }
  .main__row .content {
    padding-right: 15px;
  }
  .table-top__btn-1 .btn-0 {
    padding: 3px 10px 2px;
  }
  .table-top__btn-2 .link-0 {
    font-size: 12px;

    @media (max-width: 420px) {
      line-height: initial;
    }
  }
  .table-top__stars__item svg {
    width: 17px;
  }
  .table-top__stars__item {
    margin: 0 1px;
  }
  .table-top__logo {
    margin-bottom: 7px;
  }
  .table-top__logo img {
    max-width: 90%;
  }
  .img-center-right img,
  .img-center-left img {
    max-width: 200px;
  }
  .header__logo a img {
    max-width: 220px;
  }
  .header__nav > ul > li {
    margin-right: 10px;
  }
  .header__nav > ul {
    font-size: 14px;
  }
  .header__nav > ul > li .btn-0 {
    padding: 3px 15px;
  }
  .table-top__users-votes {
    font-size: 12px;
  }
  .top-min__item__info__score__star svg {
    width: 6px;
    height: auto;
  }
  .top-min__item__info__score__number {
    font-size: 30px;
  }
  .top-min__item__left {
    padding: 15px 10px;
  }
  .header__logo a img {
    max-width: 195px;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 20px 0;
    position: relative;
    &__logo a img {
      max-width: 200px;
    }
    &__nav {
      & > .btn-open-nav {
        display: block;
        width: 35px;
        background: transparent;
        border: none;
        & > span {
          display: block;
          width: 100%;
          height: 4px;
          margin-bottom: 6px;
          background: #ffffff;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      & > ul {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        background: rgba(0, 0, 0, 0.9);
        padding: 10px 0 0;
        & > li {
          display: block;
          margin: 0;
          & > a {
            display: block;
            padding: 10px 15px;
            &.btn-0 {
              border-radius: 0;
              padding: 10px 15px;
            }
          }
        }
        &.open {
          z-index: 11;
          display: block;
        }
      }
    }
  }
  .btn-banner {
    font-size: 24px;
    margin-top: 0;
  }
  .react-parallax-bgimage {
    height: 700px !important;
    left: 45% !important;
  }
  .main-block {
    padding: 20px 0;
  }
  .hero-text__tx {
    width: 100%;
    text-align: center;
    h1 {
      font-size: 24px;
    }
  }
  .hero-text__btn-bl {
    text-align: center;
  }
  .bg-block {
    padding: 150px;
    &__text h1 {
      max-width: 100%;
      font-size: 22px;
      line-height: 30px;
      letter-spacing: 0.5px;
      margin-bottom: 20px;
      max-width: 67%;
    }
  }
  .title-block h3 {
    font-size: 22px;
  }
  .title-block {
    margin-bottom: 30px;
  }
  .block-2__col {
    width: 100%;
  }
  .block-2__item {
    margin-bottom: 15px;
  }
  .top__col {
    width: 100%;
  }
  .top__item {
    margin-bottom: 15px;
  }
  .block-4__col {
    width: 100%;
  }
  .block-4__item {
    margin-bottom: 15px;
  }
  .footer-widget {
    margin-bottom: 20px;
  }
  .footer__top {
    padding: 40px 0;
  }
  .footer__top .footer-widget ul {
    font-size: 14px;
  }
  .footer__top .footer-widget__title {
    color: #333333;
    margin-top: 0;
    margin-bottom: 22px;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 18px;
    &:after {
      margin-top: 10px;
    }
  }
  .hero-text__tx p {
    line-height: 1.5;
  }
  .title-block p {
    font-size: 14px;
  }
  .footer__nav {
    font-size: 12px;
  }
  .footer__nav > li {
    margin: 0 8px;
  }
  .footer__top {
    padding: 25px 0;
  }
  .footer__bottom {
    padding: 14px 0;
    text-align: center;
    font-size: 10px;
  }
  .main__row {
    flex-wrap: wrap;
  }
  .main__row .content {
    padding-right: 0;
  }
  .main__row .right-col {
    flex: 100% 1;
    width: 100%;
    max-width: initial;
    padding-top: 30px;
  }
  .top-min__item__logo {
    text-align: center;
  }
  .top-min__item__logo img {
    max-width: 160px;
  }
  .info-reviews__row {
    flex-wrap: wrap;
  }
  .info-reviews__col {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .main__row .content__text h1 {
    font-size: 24px;
  }
  .main__row .content__text h2 {
    font-size: 20px;
  }
  .img-center-right,
  .img-center-left {
    float: none;
    margin: 0 0 20px;
    img {
      max-width: 100%;
    }
  }
  .top-min__item__logo img {
    max-width: 90%;
  }
  .table-top__tr {
    flex-direction: column;
    padding-bottom: 30px;
  }
  .table-top__td {
    width: 100% !important;

    &:nth-child(4),
    &:nth-child(5) {
      display: none;
    }
  }

  .table-top__logo {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      max-width: 70%;
    }

    .table-top__score {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 34px;
      margin-bottom: 0px;
      margin-top: 0;
      width: 68px;
      height: 68px;
      border: 2px solid #2e7675;
      border-radius: 50%;
    }
  }

  .table-top {
    .list-btns-wrap {
      display: flex;
      width: 100%;
      position: relative;
      padding-top: 15px;
      margin-top: 15px;

      &:before {
        content: '';
        display: block;
        width: 98%;
        height: 1px;
        background-color: #eaecef;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      .table-top__td:nth-child(2) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;

        & > .table-top__btn-1,
        & > .table-top__btn-2 {
          width: 140px;
          margin-left: auto;
          line-height: normal;
        }
      }
    }
  }

  .table-top__btn-1 .btn-0 {
    padding: 10px 40px;
  }

  .table-top__score {
    display: none;
  }

  .table-top__stars {
    justify-content: flex-start;
  }

  .right-col {
    position: relative !important;
    top: auto !important;
  }
  .header {
    background: #000000;
  }
  .main__row .right-col-margin {
    margin-top: 10px;
  }
  .block-4__item__content__text {
    font-size: 12px;
  }
  .table-top__stars {
    margin-top: 20px;
  }
  .table-top__stars__item {
    margin: 0 3px;
  }
  .table-top__stars__item svg {
    width: 30px;
  }
  .top-min__item__info__score__star svg {
    width: 7px;
    height: auto;
  }
  .btn-0 {
    padding: 15px 40px;
  }
}

@media (max-width: 640px) {
  .table-top__logo {
    margin-bottom: 0;
  }

  .table-top__stars__item svg {
    width: 16px;
  }

  .table-top__stars {
    margin-top: 0;
  }
}

@media (max-width: 420px) {
  .header {
    padding: 10px 0;
  }

  .main-block {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .hero-text {
    .hero-text__tx {
      h1 {
        font-size: 24px;
        margin-bottom: 0;
      }
    }

    & > .hero-text__btn-bl {
      display: none;
    }

    & > .SmallDisclosure {
      display: none;
    }
  }

  .react-parallax-bgimage {
    height: 480px !important;
  }
  .bg-block {
    padding: 120px;
  }
  .btn-banner {
    font-size: 20px;
  }

  .block-3 {
    padding: 14px 0 24px;
  }

  .container {
    .title-block {
      margin-bottom: 6px;

      & > h3 {
        display: none;
      }

      & > p {
        margin-top: 4px;
        line-height: 1.4;
        text-align: left;
      }
    }

    .table-top {
      background-color: #0000001a;
      .table-top__tr {
        padding-bottom: 14px;
        margin-bottom: 4px;
        border-top: 1px solid #f0f0f0;
        background-color: #ffffff;
      }
    }
  }
}

@media (max-width: 320px) {
  .bg-block__text h1 {
    margin-bottom: 16px;
  }
  .react-parallax-bgimage {
    height: 440px !important;
    left: 33% !important;
    margin-top: 40px;
  }
}
